<template>
  <div class="relative-position">
    <video
      v-if="media.videoUrl"
      class="absolute-center video-js"
      ref="videoPlayer"
      playsinline
      type="application/x-mpegURL"
    ></video>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { urlFor } from "src/boot/sanityUrlFor";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import { useVideoStore } from "src/stores/video";
import videojs from "video.js";
import "video.js/dist/video-js.css";

defineOptions({ name: "VideoComponent" });

const emits = defineEmits(["loaded", "updated"]);

const props = defineProps({
  activeProjectSlide: { type: Boolean, default: false },
  activeSlide: { type: Boolean, default: false },
  hover: { type: Boolean, default: false },
  media: { type: Object, default: () => {} },
  newTime: { type: Number, default: 0 },
  project: { type: Object, default: () => {} },
  skipBack: { type: Boolean, default: false },
  skipForward: { type: Boolean, default: false },
  width: { type: Number, default: 320 },
});

let player = null;

const videoStore = useVideoStore();
const { isPlaying, muted, play, playPause, volume } = storeToRefs(videoStore);
const responsiveWidth = computed(() => useResponsiveImageWidth(props.width));
const videoPlayer = ref(null);
const computedActiveProjectSlide = computed(() => props.activeProjectSlide);
const computedActiveSlide = computed(() => props.activeSlide);
const computedHover = computed(() => props.hover);
const computedNewTime = computed(() => props.newTime);
const computedSkipBack = computed(() => props.skipBack);
const computedSkipForward = computed(() => props.skipForward);
const posterSrc = computed(() =>
  props.media.image?.asset?.url
    ? urlFor(props.media.image.asset.url).width(1920).url()
    : null
);

const handleMute = (mute) => {
  if (player) player.muted(mute);
};

const handlePause = () => {
  if (player) player.pause();
  isPlaying.value[props.project.slug.current] = { playing: false };
};

const handlePlay = () => {
  if (player) player.play();
  isPlaying.value[props.project.slug.current] = { playing: true };
};

const handleSeek = (value) => {
  if (player) player.currentTime(value);
};

const handleSkip = (back) => {
  if (player)
    player.currentTime(player.currentTime() + (10000 * back ? -1 : 1));
};

const handleVolume = (volume) => {
  if (player) player.volume(volume);
};

const playMuted = () => {
  handleMute(true);
  handlePlay();
};

const playWithSound = () => {
  handleMute(muted.value);
  handlePlay();
};

onMounted(async () => {
  await nextTick();
  if (!props.media.videoUrl) return;
  player = videojs(videoPlayer.value, {
    autoplay: computedActiveProjectSlide.value,
    controls: false,
    fluid: true,
    loop: true,
    muted: false,
    poster: posterSrc.value,
    preload: "auto",
    useDevicePixelRatio: true,
  });
  player.src({ src: props.media.videoUrl });
  player.poster;
  player.load();
  player.on("loadeddata", () => {
    emits("loaded", player);
  });
  player.on("timeupdate", () => {
    emits("updated", player);
  });
});

watch(
  computedActiveProjectSlide,
  (isActiveProjectSlide) => {
    isActiveProjectSlide ? playWithSound() : handlePause();
  },
  { immediate: true }
);

watch(computedHover, (isHovering) => {
  if (computedActiveProjectSlide.value) return;
  if (!computedActiveSlide.value) return;
  isHovering ? playMuted() : handlePause();
});

watch(computedNewTime, (time) => {
  handleSeek(time);
});

watch(
  play,
  (isPlaying) => {
    if (!computedActiveProjectSlide.value) return;
    isPlaying[props.project.slug.current]?.playing
      ? handlePlay()
      : handlePause();
  },
  { deep: true }
);

watch(computedSkipBack, () => {
  handleSkip(true);
});

watch(computedSkipForward, () => {
  handleSkip(false);
});

watch(muted, (mute) => {
  handleMute(mute);
});

watch(volume, (vol) => {
  handleVolume(vol);
});
</script>
