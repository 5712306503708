<template>
  <div
    :class="active ? 'active' : 'inactive'"
    class="absolute-full relative-position"
  >
    <swiper-container
      class="absolute-full"
      init="false"
      navigation="true"
      pagination="true"
      ref="swiper"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        :key="`${index}-${slide._key}`"
      >
        <q-responsive :ratio="project.ratio" :style="`width: ${width}px`"
          ><component
            :active-project-slide="active && index === swiperIndex"
            :active-slide="index === swiperIndex"
            :hover="hover"
            :is="isComponent(slide.type)"
            :new-time="newTime"
            :media="slide"
            :project="project"
            :skip-back="skipBack"
            :skip-forward="skipForward"
            :width="width"
            @loaded="(player) => onLoaded(player)"
            @updated="(player) => onUpdated(player)"
          />
        </q-responsive>
      </swiper-slide>
    </swiper-container>
    <div
      v-if="active && slides.length > 1"
      class="backdrop-blur backdrop-dimmed swiper-button-prev"
      ref="prev"
      style="z-index: 1"
    >
      <q-btn
        class="rotate-180"
        dense
        :icon="symSharpArrowForwardIos"
        :size="$q.screen.gt.sm ? 'md' : 'sm'"
        square
        unelevated
        @click="onPrevClick()"
      />
    </div>
    <div
      v-if="active && slides.length > 1"
      class="backdrop-blur backdrop-dimmed swiper-button-next"
      ref="next"
      style="z-index: 1"
    >
      <q-btn
        dense
        :icon="symSharpArrowForwardIos"
        :size="$q.screen.gt.sm ? 'md' : 'sm'"
        square
        unelevated
        @click="onNextClick()"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { symSharpArrowForwardIos } from "@quasar/extras/material-symbols-sharp";
import { Navigation, Pagination } from "src/boot/swiper";
import { useProjectsStore } from "src/stores/projects";
import { useSwiperStore } from "src/stores/swiper";
import ImageComponent from "src/components/image/ImageComponent.vue";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "SwiperComponent" });

const emits = defineEmits(["init", "loaded", "slideChange", "updated"]);

const props = defineProps({
  active: { type: Boolean, default: false },
  hover: { type: Boolean, default: false },
  newTime: { type: Number, default: 0 },
  project: { type: Object, default: () => {} },
  ratio: { type: Number, defaut: 16 / 9 },
  skipBack: { type: Boolean, default: false },
  skipForward: { type: Boolean, default: false },
  slides: { type: Array, default: () => new Array(1).fill({}) },
  width: { type: Number, default: 640 },
});

const route = useRoute();
const projectsStore = useProjectsStore();
const { currentProject } = storeToRefs(projectsStore);
const swiperStore = useSwiperStore();
const { swiperCurrentSlides } = storeToRefs(swiperStore);
const next = ref(null);
const prev = ref(null);
const swiper = ref(null);
const swiperIndex = ref(0);

const routeSlideParam = computed(() => route.params.slide);

const currentSlide = computed(
  () => props.slides[swiperIndex.value] || props.slides[0]
);

const slideParamIndex = computed(() =>
  props.slides.findIndex(
    (slide) => slide.slug.current === routeSlideParam.value
  )
);

const computedActive = computed(() => props.active);

const handleSlideParam = () => {
  if (!props.active) return 0;
  if (!routeSlideParam.value) return 0;
};

const isComponent = (type) => {
  return type === "image" ? ImageComponent : VideoComponent;
};

const onLoaded = (player) => {
  emits("loaded", player);
};

const onNextClick = () => {
  swiper.value.swiper.slideNext();
};

const onPrevClick = () => {
  swiper.value.swiper.slidePrev();
};

const onUpdated = (player) => {
  emits("updated", player);
};

handleSlideParam();

const params = {
  autoplay: false,
  effect: "default",
  initialSlide: props.active ? slideParamIndex.value : 0,
  injectStylesUrls: ["/css/pagination-element.scss"],
  loop: props.slides.length > 1,
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: next.value,
    prevEl: prev.value,
  },
  pagination: {
    dynamicBullets: true,
  },
  runCallbacksOnInit: false,
  slidesPerView: 1,
  on: {
    init() {
      if (currentSlide.value?.slug?.current) {
        swiperCurrentSlides.value[props.project.slug.current] =
          currentSlide.value.slug.current;
      }
      emits("slideChange", currentSlide.value);
    },
    slideChange(swiper) {
      swiperIndex.value = swiper.realIndex;
      swiperCurrentSlides.value[props.project.slug.current] =
        currentSlide.value.slug.current;
      if (!props.active) return;
      emits("slideChange", currentSlide.value);
    },
  },
};

onMounted(() => {
  nextTick(() => {
    Object.assign(swiper.value, params);
    if (swiper.value) swiper.value.initialize();
  });
});

watch(currentProject, (current) => {
  if (current?.slug?.current !== props.project.slug.current) return;
  emits("slideChange", currentSlide.value);
});

watch(routeSlideParam, (slideParam) => {
  if (!props.active) return;
  if (slideParam == currentSlide.value.slug.current) return;
  if (slideParam) {
    if (slideParamIndex.value > -1)
      swiper.value.swiper.slideTo(slideParamIndex.value);
  }
});
</script>
