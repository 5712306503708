<template>
  <div class="full-width items-center justify-between q-px-sm row" ref="player">
    <div class="col-2 col-sm-1">
      <video-play-pause :project="project" />
    </div>
    <div class="col">
      <video-seek-slider
        :duration="duration"
        :time="time"
        @change="(value) => onChange(value)"
      />
    </div>
    <div class="col-2 col-sm-1 text-right"><video-mute-btn /></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import VideoPlayPause from "./VideoPlayPause.vue";
import VideoSeekSlider from "./VideoSeekSlider.vue";
import VideoMuteBtn from "./VideoMuteBtn.vue";

defineOptions({ name: "VideoPlayer" });

const emits = defineEmits(["change"]);

const props = defineProps({
  duration: { type: Number, default: 1 },
  hover: { type: Boolean, default: false },
  project: { type: Object, default: () => {} },
  time: { type: Number, default: 0 },
});

const player = ref(null);

const onChange = (value) => {
  emits("change", value);
};
</script>
